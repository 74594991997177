

export class SEOApp {

	constructor() {
		this.onReady();

	}

	onReady() {

		console.log('read');

		jQuery(document).on('click', '.seo-toggle-accordion', function(e) {

			jQuery(this).siblings('.seo-accordion').toggleClass('show');

			if(jQuery(this).siblings('.seo-accordion').hasClass('show')) {


				console.log('hide');

				jQuery(this).find('.seo-accordion-plus').hide();
				jQuery(this).find('.seo-accordion-minus').show();

				const thisEl = jQuery(this).siblings('.seo-accordion').get(0);

				jQuery(this).parents('section').find('.seo-accordion').each(function() {

					if(this !== thisEl) {
						jQuery(this).removeClass('show');
						jQuery(this).prev().find('.seo-accordion-plus').show();
						jQuery(this).prev().find('.seo-accordion-minus').hide();
					}

				})

			} else {
				jQuery(this).find('.seo-accordion-plus').show();
				jQuery(this).find('.seo-accordion-minus').hide();
			}

			e.preventDefault();
			return false;

		})
	}

}

export default SEOApp;
